define("discourse/plugins/discourse-theme-creator/discourse/templates/connectors/user-main-nav/themes", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <LinkTo @route="user.themes">
    {{d-icon "paint-brush"}}
    {{i18n "theme_creator.themes"}}
  </LinkTo>
  */
  {
    "id": "/XDnJH+B",
    "block": "[[[8,[39,0],null,[[\"@route\"],[\"user.themes\"]],[[\"default\"],[[[[1,\"\\n  \"],[1,[28,[35,1],[\"paint-brush\"],null]],[1,\"\\n  \"],[1,[28,[35,2],[\"theme_creator.themes\"],null]],[1,\"\\n\"]],[]]]]]],[],false,[\"link-to\",\"d-icon\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-theme-creator/discourse/templates/connectors/user-main-nav/themes.hbs",
    "isStrictMode": false
  });
});