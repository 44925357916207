define("discourse/plugins/discourse-theme-creator/discourse/routes/user-themes-index", ["exports", "admin/routes/admin-customize-themes-index"], function (_exports, _adminCustomizeThemesIndex) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class UserThemesIndex extends _adminCustomizeThemesIndex.default {
    templateName = "adminCustomizeThemesIndex";
    setupController() {
      super.setupController(...arguments);
      const parentController = this.controllerFor("user.themes");
      parentController.set("editingTheme", false);
    }
  }
  _exports.default = UserThemesIndex;
});