define("discourse/plugins/discourse-theme-creator/discourse/routes/user-themes-show", ["exports", "@ember/service", "discourse/routes/discourse"], function (_exports, _service, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class UserThemesShow extends _discourse.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.inject]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    serialize(model) {
      return {
        theme_id: model.get("id")
      };
    }
    model(params) {
      const all = this.modelFor("user.themes");
      const model = all.findBy("id", parseInt(params.theme_id, 10));
      return model ? model : this.router.replaceWith("user.themes.index");
    }
    setupController(controller, model) {
      controller.set("model", model);
      const parentController = this.controllerFor("user.themes");
      parentController.set("editingTheme", false);
      controller.set("allThemes", parentController.get("model"));
      const colorSchemes = parentController.get("model.colorSchemes").filterBy("theme_id", model.get("id"));
      colorSchemes.unshift(parentController.get("model.colorSchemes").findBy("id", null));
      controller.set("colorSchemes", colorSchemes);
      controller.set("colorSchemeId", model.get("color_scheme_id"));
      controller.set("advancedOverride", false);
    }
  }
  _exports.default = UserThemesShow;
});