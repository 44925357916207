define("discourse/plugins/discourse-theme-creator/discourse/components/user-theme-setting-editor", ["exports", "discourse/components/modal/json-schema-editor", "discourse-common/utils/decorators", "admin/components/theme-setting-editor"], function (_exports, _jsonSchemaEditor, _decorators, _themeSettingEditor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class UserThemeSettingEditor extends _themeSettingEditor.default {
    settingEditButton(setting) {
      if (setting.json_schema) {
        return {
          action: () => {
            this.modal.show(_jsonSchemaEditor.default, {
              model: {
                updateValue: value => {
                  this.buffered.set("value", value);
                },
                value: this.buffered.get("value"),
                settingName: setting.setting,
                jsonSchema: setting.json_schema
              }
            });
          },
          label: "admin.site_settings.json_schema.edit",
          icon: "pencil-alt"
        };
      } else if (setting.objects_schema) {
        return {
          action: () => {
            this.router.transitionTo("user.themes.show.schema", this.model.id, setting.setting);
          },
          label: "admin.customize.theme.edit_objects_theme_setting",
          icon: "pencil-alt"
        };
      }
    }
    static #_ = (() => dt7948.n(this.prototype, "settingEditButton", [(0, _decorators.default)("setting")]))();
  }
  _exports.default = UserThemeSettingEditor;
});