define("discourse/plugins/discourse-theme-creator/discourse/controllers/user-themes-edit", ["exports", "discourse/lib/computed", "admin/controllers/admin-customize-themes-edit"], function (_exports, _computed, _adminCustomizeThemesEdit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class UserThemesEdit extends _adminCustomizeThemesEdit.default {
    static #_ = (() => dt7948.g(this.prototype, "previewUrl", [(0, _computed.url)("model.id", "/user_themes/%@/preview")]))();
    #previewUrl = (() => (dt7948.i(this, "previewUrl"), void 0))();
    editRouteName = "user.themes.edit";
    showRouteName = "user.themes.show";
  }
  _exports.default = UserThemesEdit;
});