define("discourse/plugins/discourse-theme-creator/discourse/components/user-theme-translation", ["exports", "discourse/lib/computed", "admin/components/theme-translation"], function (_exports, _computed, _themeTranslation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class UserThemeTranslation extends _themeTranslation.default {
    static #_ = (() => dt7948.g(this.prototype, "updateUrl", [(0, _computed.url)("model.id", "/user_themes/%@")]))();
    #updateUrl = (() => (dt7948.i(this, "updateUrl"), void 0))();
  }
  _exports.default = UserThemeTranslation;
});