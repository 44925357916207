define("discourse/plugins/discourse-theme-creator/discourse/theme-creator-share-route-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default() {
    this.route("theme-share", {
      path: "theme/:username/:slug"
    });
    this.route("theme-share-key", {
      path: "theme/:theme_id"
    });
  }
});