define("discourse/plugins/discourse-theme-creator/discourse/initializers/enable-advanced", ["exports", "discourse/lib/plugin-api"], function (_exports, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function initializeWithApi(api) {
    api.modifyClass("component:admin-theme-editor", {
      pluginId: "discourse-theme-creator",
      allowAdvanced: true
    });
  }
  var _default = _exports.default = {
    name: "enable-advanced",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.1", initializeWithApi);
    }
  };
});