define("discourse/plugins/discourse-theme-creator/discourse/models/user-theme", ["exports", "admin/models/theme", "discourse/plugins/discourse-theme-creator/discourse/models/user-theme-settings"], function (_exports, _theme, _userThemeSettings) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class UserTheme extends _theme.default {
    static munge(json) {
      if (json.settings) {
        json.settings = json.settings.map(setting => _userThemeSettings.default.create(setting));
      }
      return json;
    }
  }
  _exports.default = UserTheme;
});