define("discourse/plugins/discourse-theme-creator/discourse/routes/user-themes", ["exports", "@ember/array/proxy", "@ember/object", "@ember/service", "discourse/routes/discourse", "admin/components/modal/install-theme", "admin/models/color-scheme-color", "I18n", "discourse/plugins/discourse-theme-creator/discourse/components/modal/user-themes-edit-local-modal", "discourse/plugins/discourse-theme-creator/discourse/models/user-color-scheme"], function (_exports, _proxy, _object, _service, _discourse, _installTheme, _colorSchemeColor, _I18n, _userThemesEditLocalModal, _userColorScheme) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class UserThemes extends _discourse.default {
    static #_ = (() => dt7948.g(this.prototype, "modal", [_service.inject]))();
    #modal = (() => (dt7948.i(this, "modal"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "router", [_service.inject]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    model() {
      return this.store.findAll("user-theme", {
        user_id: this.modelFor("user").id
      }).then(data => {
        const ColorSchemes = _proxy.default.extend({});
        const colorSchemes = ColorSchemes.create({
          content: [],
          loading: true
        });
        data.extras.color_schemes.forEach(colorScheme => {
          colorSchemes.pushObject(_userColorScheme.default.create({
            id: colorScheme.id,
            name: colorScheme.name,
            is_base: colorScheme.is_base,
            theme_id: colorScheme.theme_id,
            theme_name: colorScheme.theme_name,
            base_scheme_id: colorScheme.base_scheme_id,
            colors: colorScheme.colors.map(function (c) {
              return _colorSchemeColor.default.create({
                name: c.name,
                hex: c.hex,
                default_hex: c.default_hex
              });
            })
          }));
        });
        data.set("colorSchemes", colorSchemes);
        return data;
      });
    }
    titleToken() {
      return _I18n.default.t("theme_creator.my_themes");
    }
    addTheme(theme, afterTransition) {
      const all = this.modelFor("user.themes");
      all.pushObject(theme);
      this.router.transitionTo("user.themes.show", theme.get("id")).then(afterTransition);
    }
    static #_3 = (() => dt7948.n(this.prototype, "addTheme", [_object.action]))();
    installModal() {
      const adminCustomizeThemesController = this.controllerFor("adminCustomizeThemes");
      this.modal.show(_installTheme.default, {
        model: {
          keyGenUrl: "/user_themes/generate_key_pair",
          importUrl: "/user_themes/import",
          selection: "create",
          recordType: "user-theme",
          selectedType: adminCustomizeThemesController.currentTab,
          userId: this.modelFor("user").id,
          content: this.currentModel.content,
          installedThemes: adminCustomizeThemesController.installedThemes,
          addTheme: this.addTheme,
          updateSelectedType: type => adminCustomizeThemesController.set("currentTab", type)
        }
      });
    }
    static #_4 = (() => dt7948.n(this.prototype, "installModal", [_object.action]))();
    editLocalModal() {
      this.modal.show(_userThemesEditLocalModal.default);
    }
    static #_5 = (() => dt7948.n(this.prototype, "editLocalModal", [_object.action]))();
    refreshThemes() {
      this.refresh();
    }
    static #_6 = (() => dt7948.n(this.prototype, "refreshThemes", [_object.action]))();
  }
  _exports.default = UserThemes;
});