define("discourse/plugins/discourse-theme-creator/discourse/controllers/user-themes-show", ["exports", "@ember/object", "@ember/object/computed", "@ember/service", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/lib/computed", "discourse-common/utils/decorators", "admin/components/theme-upload-add", "admin/controllers/admin-customize-themes-show", "I18n", "discourse/plugins/discourse-theme-creator/discourse/mixins/themes-colors", "discourse/plugins/discourse-theme-creator/discourse/components/modal/user-themes-share-modal"], function (_exports, _object, _computed, _service, _ajax, _ajaxError, _computed2, _decorators, _themeUploadAdd, _adminCustomizeThemesShow, _I18n, _themesColors, _userThemesShareModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class UserThemesShow extends _adminCustomizeThemesShow.default.extend(_themesColors.default) {
    static #_ = (() => dt7948.g(this.prototype, "dialog", [_service.inject]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "modal", [_service.inject]))();
    #modal = (() => (dt7948.i(this, "modal"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "router", [_service.inject]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    parentController = (() => _object.default.create({
      model: {
        content: []
      }
    }))();
    static #_4 = (() => dt7948.g(this.prototype, "id", [(0, _computed.alias)("model.id")]))();
    #id = (() => (dt7948.i(this, "id"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "colors", [(0, _computed.alias)("quickColorScheme.colors")]))();
    #colors = (() => (dt7948.i(this, "colors"), void 0))();
    editRouteName = "user.themes.edit";
    static #_6 = (() => dt7948.g(this.prototype, "downloadUrl", [(0, _computed2.url)("model.id", "/user_themes/%@/export")]))();
    #downloadUrl = (() => (dt7948.i(this, "downloadUrl"), void 0))();
    advancedOverride = false;
    colorSchemeEditDisabled(colorSchemeId) {
      return colorSchemeId === null;
    }
    static #_7 = (() => dt7948.n(this.prototype, "colorSchemeEditDisabled", [(0, _decorators.default)("model.color_scheme_id")]))();
    showAdvanced(advancedOverride, colorSchemes, uploads, hasEditedFields, component) {
      return advancedOverride || uploads.length > 0 || colorSchemes.length > 2 || hasEditedFields || component;
    }
    static #_8 = (() => dt7948.n(this.prototype, "showAdvanced", [(0, _decorators.default)("advancedOverride", "colorSchemes", "model.uploads", "model.hasEditedFields", "model.component")]))();
    hasQuickColorScheme(scheme) {
      return !!scheme;
    }
    static #_9 = (() => dt7948.n(this.prototype, "hasQuickColorScheme", [(0, _decorators.default)("quickColorScheme")]))();
    quickColorScheme(showAdvanced, schemes) {
      if (showAdvanced) {
        return null;
      }
      const scheme = schemes.find(c => {
        return c.id !== null;
      });
      return scheme;
    }
    static #_10 = (() => dt7948.n(this.prototype, "quickColorScheme", [(0, _decorators.default)("showAdvanced", "colorSchemes")]))();
    saveMetadata() {
      return this.get("model").saveChanges("remote_theme");
    }
    static #_11 = (() => dt7948.n(this.prototype, "saveMetadata", [_object.action]))();
    showAdvancedAction() {
      this.set("advancedOverride", true);
    }
    static #_12 = (() => dt7948.n(this.prototype, "showAdvancedAction", [_object.action]))();
    saveQuickColorScheme() {
      this.set("isSaving", true);
      this.get("quickColorScheme").save().then(() => {
        this.set("isSaving", false);
      });
    }
    static #_13 = (() => dt7948.n(this.prototype, "saveQuickColorScheme", [_object.action]))();
    shareModal() {
      this.modal.show(_userThemesShareModal.default, {
        model: this.model
      });
    }
    static #_14 = (() => dt7948.n(this.prototype, "shareModal", [_object.action]))();
    addUploadModal() {
      this.modal.show(_themeUploadAdd.default, {
        model: {
          themeFields: this.model.theme_fields,
          addUpload: this.addUpload,
          uploadUrl: "/user_themes/upload_asset"
        }
      });
    }
    static #_15 = (() => dt7948.n(this.prototype, "addUploadModal", [_object.action]))();
    createColorScheme() {
      this.set("creatingColorScheme", true);
      const theme_id = this.get("model.id");
      (0, _ajax.ajax)(`/user_themes/${theme_id}/colors`, {
        type: "POST",
        data: {}
      }).then(() => {
        this.set("creatingColorScheme", false);
        this.send("refreshThemes");
      }).catch(_ajaxError.popupAjaxError);
    }
    static #_16 = (() => dt7948.n(this.prototype, "createColorScheme", [_object.action]))();
    destroyColorScheme() {
      this.get("colorSchemes").findBy("id", this.get("model.color_scheme_id")).destroy().then(() => {
        this.send("refreshThemes");
      });
    }
    static #_17 = (() => dt7948.n(this.prototype, "destroyColorScheme", [_object.action]))();
    destroy() {
      return this.dialog.deleteConfirm({
        message: _I18n.default.t("theme_creator.delete_confirm"),
        didConfirm: () => {
          const model = this.get("model");
          model.destroyRecord().then(() => {
            this.get("allThemes").removeObject(model);
            this.router.transitionTo("user.themes");
          });
        }
      });
    }
    static #_18 = (() => dt7948.n(this.prototype, "destroy", [_object.action]))();
  }
  _exports.default = UserThemesShow;
});