define("discourse/plugins/discourse-theme-creator/discourse/templates/connectors/admin-customize-themes-list-item/theme-user-icon", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{avatar theme.user imageSize="tiny"}}
  */
  {
    "id": "VNwLnzuD",
    "block": "[[[1,[28,[35,0],[[30,0,[\"theme\",\"user\"]]],[[\"imageSize\"],[\"tiny\"]]]],[1,[28,[32,0],[\"[[\\\"The `theme` property path was used in the `discourse/plugins/discourse-theme-creator/discourse/templates/connectors/admin-customize-themes-list-item/theme-user-icon.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.theme}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"avatar\"]]",
    "moduleName": "discourse/plugins/discourse-theme-creator/discourse/templates/connectors/admin-customize-themes-list-item/theme-user-icon.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});