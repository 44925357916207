define("discourse/plugins/discourse-theme-creator/discourse/routes/user-themes-edit", ["exports", "@ember/object", "@ember/service", "discourse/routes/discourse", "I18n"], function (_exports, _object, _service, _discourse, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class _default extends _discourse.default {
    static #_ = (() => dt7948.g(this.prototype, "dialog", [_service.inject]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "router", [_service.inject]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    templateName = "adminCustomizeThemesEdit";
    model(params) {
      const all = this.modelFor("user.themes");
      const model = all.findBy("id", parseInt(params.theme_id, 10));
      return model ? {
        model,
        target: params.target,
        field_name: params.field_name
      } : this.router.replaceWith("user.themes.index");
    }
    serialize(wrapper) {
      return {
        model: wrapper.model,
        target: wrapper.target || "common",
        field_name: wrapper.field_name || "scss",
        theme_id: wrapper.model.get("id")
      };
    }
    setupController(controller, wrapper) {
      const fields = wrapper.model.get("fields")[wrapper.target].map(f => f.name);
      if (!fields.includes(wrapper.field_name)) {
        this.router.transitionTo("user.themes.edit", wrapper.model.id, wrapper.target, fields[0]);
        return;
      }
      controller.set("model", wrapper.model);
      controller.setTargetName(wrapper.target || "common");
      controller.set("fieldName", wrapper.field_name || "scss");
      this.controllerFor("user.themes").set("editingTheme", true);
      this.set("shouldAlertUnsavedChanges", true);
    }
    willTransition(transition) {
      if (this.get("controller.model.changed") && this.get("shouldAlertUnsavedChanges") && transition.intent.name !== this.routeName) {
        transition.abort();
        this.dialog.confirm({
          message: _I18n.default.t("admin.customize.theme.unsaved_changes_alert"),
          confirmButtonLabel: "admin.customize.theme.discard",
          cancelButtonLabel: "admin.customize.theme.stay",
          didConfirm: () => {
            this.set("shouldAlertUnsavedChanges", false);
            transition.retry();
          }
        });
      }
    }
    static #_3 = (() => dt7948.n(this.prototype, "willTransition", [_object.action]))();
  }
  _exports.default = _default;
});