define("discourse/plugins/discourse-theme-creator/discourse/controllers/user-themes-colors", ["exports", "@ember/controller", "@ember/object", "@ember/object/computed", "discourse/plugins/discourse-theme-creator/discourse/mixins/themes-colors"], function (_exports, _controller, _object, _computed, _themesColors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class UserThemesColors extends _controller.default.extend(_themesColors.default) {
    static #_ = (() => dt7948.g(this.prototype, "id", [(0, _computed.alias)("model.theme_id")]))();
    #id = (() => (dt7948.i(this, "id"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "colors", [(0, _computed.alias)("model.colors")]))();
    #colors = (() => (dt7948.i(this, "colors"), void 0))();
    save() {
      this.set("isSaving", true);
      this.get("model").save().then(() => {
        this.set("isSaving", false);
      });
    }
    static #_3 = (() => dt7948.n(this.prototype, "save", [_object.action]))();
  }
  _exports.default = UserThemesColors;
});