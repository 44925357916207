define("discourse/plugins/discourse-theme-creator/discourse/controllers/user-themes", ["exports", "@ember/controller", "discourse-common/utils/decorators"], function (_exports, _controller, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class UserThemes extends _controller.default {
    installedThemes(themes) {
      return themes.map(t => t.name);
    }
    static #_ = (() => dt7948.n(this.prototype, "installedThemes", [(0, _decorators.default)("model", "model.@each.component")]))();
  }
  _exports.default = UserThemes;
});