define("discourse/plugins/discourse-theme-creator/discourse/components/theme-metadata-field", ["exports", "@ember/component", "@ember/object", "@ember-decorators/component"], function (_exports, _component, _object, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ThemeMetadataField = dt7948.c(class ThemeMetadataField extends _component.default {
    value = null;
    oldValue = null;
    editing = false;
    startEditing() {
      this.set("oldValue", this.value);
      this.set("editing", true);
    }
    static #_ = (() => dt7948.n(this.prototype, "startEditing", [_object.action]))();
    cancelEditing() {
      this.set("value", this.oldValue);
      this.set("editing", false);
    }
    static #_2 = (() => dt7948.n(this.prototype, "cancelEditing", [_object.action]))();
    finishedEditing() {
      this.save().then(() => {
        this.set("editing", false);
      });
    }
    static #_3 = (() => dt7948.n(this.prototype, "finishedEditing", [_object.action]))();
  }, [(0, _component2.classNames)("metadata-field"), (0, _component2.tagName)("div")]);
  var _default = _exports.default = ThemeMetadataField;
});