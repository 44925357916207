define("discourse/plugins/discourse-theme-creator/discourse/routes/user-themes-show-schema", ["exports", "@ember/service", "discourse/routes/discourse"], function (_exports, _service, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class UserThemesShowSchema extends _discourse.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.inject]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    async model(params) {
      const all = this.modelFor("user.themes");
      const theme = all.findBy("id", parseInt(params.theme_id, 10));
      const setting = theme.settings.findBy("setting", params.setting_name);
      await setting.loadMetadata(theme.id);
      return {
        theme,
        setting
      };
    }
  }
  _exports.default = UserThemesShowSchema;
});