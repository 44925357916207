define("discourse/plugins/discourse-theme-creator/discourse/theme-creator-user-route-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    resource: "user",
    path: "u/:username",
    map() {
      this.route("themes", function () {
        this.route("show", {
          path: "/:theme_id"
        });
        this.route("show.schema", {
          path: "/:theme_id/schema/:setting_name"
        });
        this.route("edit", {
          path: "/:theme_id/:target/:field_name/edit"
        });
        this.route("colors", {
          path: "/:theme_id/colors/:color_scheme_id"
        });
      });
    }
  };
});