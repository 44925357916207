define("discourse/plugins/discourse-theme-creator/discourse/routes/user-themes-colors", ["exports", "@ember/service", "discourse/routes/discourse"], function (_exports, _service, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class _default extends _discourse.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.inject]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    serialize(model) {
      return {
        theme_id: model.get("theme_id"),
        id: model.get("id")
      };
    }
    model(params) {
      const schemes = this.modelFor("user.themes").get("colorSchemes");
      const model = schemes.findBy("id", parseInt(params.color_scheme_id, 10));
      if (!model || model.theme_id !== parseInt(params.theme_id, 10)) {
        this.router.replaceWith("user.themes.index");
      }
      return model;
    }
  }
  _exports.default = _default;
});