define("discourse/plugins/discourse-theme-creator/discourse/models/user-theme-settings", ["exports", "discourse/lib/ajax", "discourse/lib/ajax-error", "admin/models/theme-settings"], function (_exports, _ajax, _ajaxError, _themeSettings) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class UserThemeSettings extends _themeSettings.default {
    loadMetadata(themeId) {
      return (0, _ajax.ajax)(`/user_themes/${themeId}/objects_setting_metadata/${this.setting}.json`).then(result => this.set("metadata", result)).catch(_ajaxError.popupAjaxError);
    }
    updateSetting(themeId, newValue) {
      if (this.objects_schema) {
        newValue = JSON.stringify(newValue);
      }
      return (0, _ajax.ajax)(`/user_themes/${themeId}/setting`, {
        type: "PUT",
        data: {
          name: this.setting,
          value: newValue
        }
      });
    }
  }
  _exports.default = UserThemeSettings;
});