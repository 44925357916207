define("discourse/plugins/discourse-theme-creator/discourse/templates/theme-share", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <UserThemesView @model={{@model}} />
  */
  {
    "id": "nQXDUtpB",
    "block": "[[[8,[39,0],null,[[\"@model\"],[[30,1]]],null]],[\"@model\"],false,[\"user-themes-view\"]]",
    "moduleName": "discourse/plugins/discourse-theme-creator/discourse/templates/theme-share.hbs",
    "isStrictMode": false
  });
});