define("discourse/plugins/discourse-theme-creator/discourse/components/user-schema-theme-setting-editor", ["exports", "@ember/object", "discourse/lib/ajax-error", "admin/components/schema-theme-setting/editor"], function (_exports, _object, _ajaxError, _editor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class UserSchemaThemeSettingEditor extends _editor.default {
    saveChanges() {
      this.saveButtonDisabled = true;
      this.args.setting.updateSetting(this.args.themeId, this.data).then(result => {
        this.args.setting.set("value", result[this.args.setting.setting]);
        this.router.transitionTo("user.themes.show", this.args.themeId);
      }).catch(e => {
        if (e.jqXHR.responseJSON && e.jqXHR.responseJSON.errors) {
          this.validationErrorMessage = e.jqXHR.responseJSON.errors[0];
        } else {
          (0, _ajaxError.popupAjaxError)(e);
        }
      }).finally(() => this.saveButtonDisabled = false);
    }
    static #_ = (() => dt7948.n(this.prototype, "saveChanges", [_object.action]))();
  }
  _exports.default = UserSchemaThemeSettingEditor;
});