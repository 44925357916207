define("discourse/plugins/discourse-theme-creator/discourse/models/user-color-scheme", ["exports", "discourse/lib/ajax", "admin/models/color-scheme"], function (_exports, _ajax, _colorScheme) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class UserColorScheme extends _colorScheme.default {
    save() {
      const data = {};
      data.name = this.get("name");
      data.colors = [];
      this.get("colors").forEach(c => {
        if (c.get("changed")) {
          data.colors.pushObject({
            name: c.get("name"),
            hex: c.get("hex")
          });
        }
      });
      const theme_id = this.get("theme_id");
      const this_id = this.get("id");
      return (0, _ajax.ajax)(`/user_themes/${theme_id}/colors/${this_id}`, {
        data: JSON.stringify({
          color_scheme: data
        }),
        type: "PUT",
        dataType: "json",
        contentType: "application/json"
      }).then(() => {
        this.startTrackingChanges();
        this.get("colors").forEach(c => {
          c.startTrackingChanges();
        });
      });
    }
    destroy() {
      if (this.get("id")) {
        return (0, _ajax.ajax)(`/user_themes/${this.get("theme_id")}/colors/${this.get("id")}`, {
          type: "DELETE"
        });
      }
    }
  }
  _exports.default = UserColorScheme;
});