define("discourse/plugins/discourse-theme-creator/discourse/initializers/add-styleguide-button", ["exports", "discourse/lib/plugin-api", "I18n"], function (_exports, _pluginApi, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function initializeWithApi(api) {
    api.addNavigationBarItem({
      name: "styleguide",
      displayName: _I18n.default.t("theme_creator.styleguide"),
      title: _I18n.default.t("theme_creator.styleguide"),
      href: "/styleguide"
    });
  }
  var _default = _exports.default = {
    name: "add-styleguide-button",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.1", initializeWithApi);
    }
  };
});