define("discourse/plugins/discourse-theme-creator/discourse/mixins/themes-colors", ["exports", "@ember/object/mixin", "discourse/lib/computed", "discourse-common/utils/decorators", "I18n"], function (_exports, _mixin, _computed, _decorators, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _mixin.default.create(dt7948.p({
    previewUrl: (0, _computed.url)("id", "/user_themes/%@/preview"),
    saveButtonText(isSaving) {
      return isSaving ? _I18n.default.t("saving") : _I18n.default.t("theme_creator.save");
    },
    hidePreview(colors) {
      return colors && colors.some(color => color.get("changed"));
    }
  }, [["method", "saveButtonText", [(0, _decorators.default)("isSaving")]], ["method", "hidePreview", [(0, _decorators.default)("colors.@each.changed")]]]));
});