define("discourse/plugins/discourse-theme-creator/discourse/components/modal/user-themes-edit-local-modal", ["exports", "@ember/component", "@glimmer/tracking", "@ember/object", "discourse/lib/ajax", "discourse/lib/ajax-error", "@ember/template-factory"], function (_exports, _component, _tracking, _object, _ajax, _ajaxError, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModal
    id="theme-creator-edit-local-modal"
    @title={{i18n "theme_creator.edit_local"}}
    @closeModal={{@closeModal}}
  >
    <p>{{html-safe (i18n "theme_creator.edit_local_description")}}</p>
  
    <h3>{{i18n "theme_creator.edit_local_key"}}</h3>
  
    <ConditionalLoadingSpinner @condition={{this.loading}}>
      {{#if this.key}}
        <pre>{{this.key}}</pre>
      {{else}}
        <DButton
          @action={{this.showKey}}
          @icon="eye"
          @label="theme_creator.show_key"
        />
      {{/if}}
    </ConditionalLoadingSpinner>
  
    <p>{{html-safe (i18n "theme_creator.revoke_instructions")}}</p>
  </DModal>
  */
  {
    "id": "jFkAI2OX",
    "block": "[[[8,[39,0],[[24,1,\"theme-creator-edit-local-modal\"]],[[\"@title\",\"@closeModal\"],[[28,[37,1],[\"theme_creator.edit_local\"],null],[30,1]]],[[\"default\"],[[[[1,\"\\n  \"],[10,2],[12],[1,[28,[35,2],[[28,[37,1],[\"theme_creator.edit_local_description\"],null]],null]],[13],[1,\"\\n\\n  \"],[10,\"h3\"],[12],[1,[28,[35,1],[\"theme_creator.edit_local_key\"],null]],[13],[1,\"\\n\\n  \"],[8,[39,3],null,[[\"@condition\"],[[30,0,[\"loading\"]]]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,0,[\"key\"]],[[[1,\"      \"],[10,\"pre\"],[12],[1,[30,0,[\"key\"]]],[13],[1,\"\\n\"]],[]],[[[1,\"      \"],[8,[39,5],null,[[\"@action\",\"@icon\",\"@label\"],[[30,0,[\"showKey\"]],\"eye\",\"theme_creator.show_key\"]],null],[1,\"\\n\"]],[]]],[1,\"  \"]],[]]]]],[1,\"\\n\\n  \"],[10,2],[12],[1,[28,[35,2],[[28,[37,1],[\"theme_creator.revoke_instructions\"],null]],null]],[13],[1,\"\\n\"]],[]]]]]],[\"@closeModal\"],false,[\"d-modal\",\"i18n\",\"html-safe\",\"conditional-loading-spinner\",\"if\",\"d-button\"]]",
    "moduleName": "discourse/plugins/discourse-theme-creator/discourse/components/modal/user-themes-edit-local-modal.hbs",
    "isStrictMode": false
  });
  class UserThemesEditLocalModal extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "loading", [_tracking.tracked]))();
    #loading = (() => (dt7948.i(this, "loading"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "key", [_tracking.tracked]))();
    #key = (() => (dt7948.i(this, "key"), void 0))();
    async showKey() {
      this.loading = true;
      try {
        const data = await (0, _ajax.ajax)("/user_themes/fetch_api_key", {
          type: "POST"
        });
        this.loading = false;
        this.key = data["api_key"];
      } catch (error) {
        return (0, _ajaxError.popupAjaxError)(error);
      }
    }
    static #_3 = (() => dt7948.n(this.prototype, "showKey", [_object.action]))();
  }
  _exports.default = UserThemesEditLocalModal;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, UserThemesEditLocalModal);
});