define("discourse/plugins/discourse-theme-creator/discourse/routes/theme-share-key", ["exports", "discourse/lib/ajax", "discourse/routes/discourse"], function (_exports, _ajax, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ThemeShareKey extends _discourse.default {
    templateName = "theme-share";
    async model(params) {
      const response = await (0, _ajax.ajax)(`/theme/${params.theme_id}.json`);
      return response.theme;
    }
  }
  _exports.default = ThemeShareKey;
});