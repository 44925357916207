define("discourse/plugins/discourse-theme-creator/discourse/adapters/user-theme", ["exports", "discourse/adapters/rest", "discourse/lib/ajax", "admin/adapters/theme"], function (_exports, _rest, _ajax, _theme) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class UserThemeAdapter extends _theme.default {
    typeField = "theme";
    basePath() {
      return "/";
    }

    // Override update method
    // Possible PR to core to make typeField configurable?
    update(store, type, id, attrs) {
      const data = {};
      const typeField = this.get("typeField");
      data[typeField] = attrs;
      return (0, _ajax.ajax)(this.pathFor(store, type, id), this.getPayload("PUT", data)).then(function (json) {
        return new _rest.Result(json[typeField], json);
      });
    }
    createRecord(store, type, attrs) {
      const data = {};
      const typeField = this.get("typeField");
      data[typeField] = attrs;
      return (0, _ajax.ajax)(this.pathFor(store, type), this.getPayload("POST", data)).then(function (json) {
        return new _rest.Result(json[typeField], json);
      });
    }
    afterFindAll(results) {
      results = super.afterFindAll(results);
      results.forEach(theme => {
        if (!theme.get("remote_theme")) {
          theme.set("remote_theme", {});
        }
      });
      return results;
    }
  }
  _exports.default = UserThemeAdapter;
});