define("discourse/plugins/discourse-theme-creator/discourse/components/color-scheme-editor", ["exports", "@ember/component", "@ember/object"], function (_exports, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ColorSchemeEditor extends _component.default {
    revert(color) {
      color.revert();
    }
    static #_ = (() => dt7948.n(this.prototype, "revert", [_object.action]))();
    undo(color) {
      color.undo();
    }
    static #_2 = (() => dt7948.n(this.prototype, "undo", [_object.action]))();
  }
  _exports.default = ColorSchemeEditor;
});