define("discourse/plugins/discourse-theme-creator/discourse/connectors/user-main-nav/themes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    shouldRender(args, component) {
      const current = component.currentUser;
      return current && (current.id === args.model.get("id") || current.staff);
    }
  };
});